



































import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { Transposer } from '../utils/chordtransposer/chordtransposer';
import VueFileToolbarMenu from 'vue-file-toolbar-menu';
import VueDocumentEditor from 'vue-document-editor'

@Component({
    components: {
        VueDocumentEditor,
        VueFileToolbarMenu
    },
})
export default class EditorCanzone extends Vue {
    @Prop({ required: false }) readonly canzoneId!: any;
    @Prop({ required: false }) readonly disabled!: boolean;
    @Prop({ required: false }) readonly testo!: string;
    @Prop({ required: false }) readonly readonly!: boolean;

    public getTestoCanzone(): string {
        return this.content.join();
    }

    public clearTestoCanzone() {
        this.content = [];
    }


    get screen_width() {
        return window.innerWidth;
    }

    get isSmallScreen() {
        return this.screen_width < 576;
    }
    get isMediumSmallScreen() {
        return this.screen_width > 576 && this.screen_width < 769;
    }
    get isMediumBigScreen() {
        return this.screen_width > 769 && this.screen_width < 1024;
    }

    get default_Page_format() {
        if (this.isSmallScreen)
            return [90, 297];
        else if (this.isMediumSmallScreen) {
            return [210, 297];
        }
        else if (this.isMediumBigScreen) {
            return [95, 297];
        }
        else {
            return [210, 297];
        }
    }

    get default_zoom() {
        if (this.isSmallScreen)
            return 0.75;
        else if (this.isMediumSmallScreen) {
            return 0.75;
        }
        else if (this.isMediumBigScreen) {
            return 0.8;
        }
        else {
            return 0.8;
        }
    }

    get default_display() {
        return "grid";
    }

    get default_page_margins() {
        if (this.isSmallScreen)
            return "5mm";
        else if (this.isMediumSmallScreen) {
            return "5mm";
        }
        else if (this.isMediumBigScreen) {
            return "5mm";
        }
        else {
            return "10mm 15mm";
        }
    }

    private zoom: any = this.default_zoom;
    private zoom_min: any = 0.10;
    private zoom_max: any = 5.0;
    private page_format_mm: any = this.default_Page_format;
    private page_margins: any = this.default_page_margins;
    private display: any = this.default_display; // ["grid", "vertical", "horizontal"]
    private editor_mounted: boolean = false; // will be true after this component is mounted
    private undo_count: any = -1; // contains the number of times user can undo (= current position in content_history)
    private content_history: any = []; // contains the content states for undo/redo operations
    private _mute_next_content_watcher: boolean = false;
    public content: string[] = [];

    private nomeStorePageformat: string = "";
    get pageformatComputed() {
        const pageFormatSorage = localStorage.getItem(this.nomeStorePageformat);
        let pageFormat = pageFormatSorage ? JSON.parse(pageFormatSorage) : this.default_Page_format;
        return pageFormat;
    }
    set pageformatComputed(newValue: any) {
        if (newValue != null && this.nomeStorePageformat != "") {
            localStorage.setItem(this.nomeStorePageformat, JSON.stringify(newValue) );
        }
    }

    private nomeStoreZoom: string = "";
    get zoomComputed() {
        const zoomSorage = localStorage.getItem(this.nomeStoreZoom);
        let zoom = zoomSorage ? JSON.parse(zoomSorage) : this.default_zoom;
        return parseFloat(zoom);
    }
    set zoomComputed(newValue: number) {
        if (newValue != null && this.nomeStoreZoom != "") {
            localStorage.setItem(this.nomeStoreZoom, JSON.stringify(newValue));
        }
    }

    private nomeStoreDisplay: string = "";
    get displayComputed() {
        const displaySorage = localStorage.getItem(this.nomeStoreDisplay);
        let display = displaySorage ? JSON.parse(displaySorage) : this.default_display;
        return display;
    }
    set displayComputed(newValue: string) {
        if (newValue != null && this.nomeStoreDisplay != "") {
            localStorage.setItem(this.nomeStoreDisplay, JSON.stringify(newValue));
        }
    }

    get menu() {
        var _menu = [
            // Main commands
            //{ text: "New", title: "New", icon: "description", click: () => { if (confirm("This will create an empty document. Are you sure?")) { this.content = [""]; this.resetContentHistory(); } } },
            { icon: "print", title: "Print", click: () => this.print() },
            { is: "spacer" },
            // Undo / redo commands
            { title: "Undo", icon: "undo", disabled: !this.can_undo, hotkey: this.isMacLike ? "command+z" : "ctrl+z", click: () => this.undo() },
            { title: "Redo", icon: "redo", disabled: !this.can_redo, hotkey: this.isMacLike ? "shift+command+z" : "ctrl+y", click: () => this.redo() },
            { is: "spacer" },
            // Rich text menus
            { icon: "format_align_left", title: "Align left", active: this.isLeftAligned, disabled: !this.current_text_style, hotkey: this.isMacLike ? "shift+command+l" : "ctrl+shift+l", click: () => document.execCommand("justifyLeft") },
            { icon: "format_align_center", title: "Align center", active: this.isCentered, disabled: !this.current_text_style, hotkey: this.isMacLike ? "shift+command+e" : "ctrl+shift+e", click: () => document.execCommand("justifyCenter") },
            { icon: "format_align_right", title: "Align right", active: this.isRightAligned, disabled: !this.current_text_style, hotkey: this.isMacLike ? "shift+command+r" : "ctrl+shift+r", click: () => document.execCommand("justifyRight") },
            { icon: "format_align_justify", title: "Justify content", active: this.isJustified, disabled: !this.current_text_style, hotkey: this.isMacLike ? "shift+command+j" : "ctrl+shift+j", click: () => document.execCommand("justifyFull") },
            { is: "separator" },
            { icon: "format_bold", title: "Bold", active: this.isBold, disabled: !this.current_text_style, hotkey: this.isMacLike ? "command+b" : "ctrl+b", click: () => document.execCommand("bold") },
            { icon: "format_italic", title: "Italic", active: this.isItalic, disabled: !this.current_text_style, hotkey: this.isMacLike ? "command+i" : "ctrl+i", click: () => document.execCommand("italic") },
            { icon: "format_underline", title: "Underline", active: this.isUnderline, disabled: !this.current_text_style, hotkey: this.isMacLike ? "command+u" : "ctrl+u", click: () => document.execCommand("underline") },
            { icon: "format_strikethrough", title: "Strike through", active: this.isStrikeThrough, disabled: !this.current_text_style, click: () => document.execCommand("strikethrough") },
            { is: "button-color", type: "compact", menu_class: "align-center", disabled: !this.current_text_style, color: this.curColor, update_color: (new_color: any) => document.execCommand('foreColor', false, new_color.hex8) },
            { is: "separator" },
            { icon: "format_list_numbered", title: "Numbered list", active: this.isNumberedList, disabled: !this.current_text_style, click: () => document.execCommand("insertOrderedList") },
            { icon: "format_list_bulleted", title: "Bulleted list", active: this.isBulletedList, disabled: !this.current_text_style, click: () => document.execCommand("insertUnorderedList") },
            { html: "<b>H1</b>", title: "Header 1", active: this.isH1, disabled: !this.current_text_style, click: () => document.execCommand('formatBlock', false, '<h1>') },
            { html: "<b>H2</b>", title: "Header 2", active: this.isH2, disabled: !this.current_text_style, click: () => document.execCommand('formatBlock', false, '<h2>') },
            { html: "<b>H3</b>", title: "Header 3", active: this.isH3, disabled: !this.current_text_style, click: () => document.execCommand('formatBlock', false, '<h3>') },
            { icon: "format_clear", title: "Clear format", disabled: !this.current_text_style, click() { document.execCommand('removeFormat'); document.execCommand('formatBlock', false, '<div>'); } },
            { icon: "splitscreen", title: "Page break", disabled: !this.current_text_style, click: () => this.insertPageBreak() },
            { is: "spacer" },
            { icon: "music_note", text: "+", title: "Up Semitono", disabled: false, click: () => this.upSemitono() },
            { icon: "music_note", text: "-", title: "Down Semitono", disabled: false, click: () => this.downSemitono() },
            { is: "separator" },
            { html: "<b>A+</b>", title: "Increase Text", disabled: !this.disabled, click: () => this.increaseFontSize('content', 0.5) },
            { html: "<b>A-</b>", title: "Decrease Text", disabled: !this.disabled, click: () => this.decreaseFontSize('content', 0.5) },
            { is: "separator" },
            { icon: "play_arrow",/* text: "Slow",*/ title: "scroll slow", disabled: !this.disabled, click: () => this.triggerScrollToSlowly() },
            { icon: "forward",/* text: "Normal",*/ title: "scroll normal", disabled: !this.disabled, click: () => this.triggerScrollToMedium() },
            { icon: "fast_forward", /*text: "Fast",*/ title: "scroll fast", disabled: !this.disabled, click: () => this.triggerScrollToFast() },
            { is: "spacer" },
            { // Format menu
                text: this.current_format_name,
                title: "Format",
                icon: "crop_free",
                chevron: true,
                menu: this.formats.map(([text, w, h]) => {
                    return {
                        text,
                        active: (this.page_format_mm[0] == w && this.page_format_mm[1] == h),
                        click: () => { this.page_format_mm = [w, h]; }
                    }
                }),
                menu_width: 80,
                menu_height: 280
            },
            { // Margins menu
                text: this.current_margins_name,
                title: "Margins",
                icon: "select_all",
                chevron: true,
                menu: this.margins.map(([text, value]) => {
                    return {
                        text: text + " (" + value + ")",
                        active: (this.page_margins == value),
                        click: () => { this.page_margins = value; }
                    }
                }),
                menu_width: 200,
                menu_class: "align-center"
            },
            { // Zoom menu
                text: Math.floor(this.zoom * 100) + "%",
                title: "Zoom",
                icon: "zoom_in",
                chevron: true,
                menu: [
                    ["200%", 2.0],
                    ["150%", 1.5],
                    ["125%", 1.25],
                    ["100%", 1.0],
                    ["90%", 0.90],
                    ["85%", 0.85],
                    ["80%", 0.80],
                    ["75%", 0.75],
                    ["70%", 0.70],
                    ["60%", 0.60],
                    ["50%", 0.5],
                    ["25%", 0.25]
                ].map(([text, zoom]) => {
                    return {
                        text,
                        active: this.zoom == zoom,
                        click: () => { this.zoom = zoom; }
                    }
                }),
                menu_width: 80,
                menu_height: 280,
                menu_class: "align-center"
            },
            { // Display mode menu
                title: "Display",
                icon: this.display == "horizontal" ? "view_column" : (this.display == "vertical" ? "view_stream" : "view_module"),
                chevron: true,
                menu: [{
                    icon: "view_module",
                    active: this.display == "grid",
                    click: () => { this.display = "grid"; }
                }, {
                    icon: "view_column",
                    active: this.display == "horizontal",
                    click: () => { this.display = "horizontal"; }
                }, {
                    icon: "view_stream",
                    active: this.display == "vertical",
                    click: () => { this.display = "vertical"; }
                }],
                menu_width: 55,
                menu_class: "align-right"
            }
        ];;
        if (this.disabled) {
            _menu = [
                { icon: "print", title: "Print", click: () => this.print() },
                { is: "spacer" },
                { icon: "music_note", text: "+", title: "Up Semitono", disabled: false, click: () => this.upSemitono() },
                { icon: "music_note", text: "-", title: "Down Semitono", disabled: false, click: () => this.downSemitono() },
                { is: "separator" },
                { html: "<b>A+</b>", title: "Increase Text", disabled: !this.disabled, click: () => this.increaseFontSize('content', 0.5) },
                { html: "<b>A-</b>", title: "Decrease Text", disabled: !this.disabled, click: () => this.decreaseFontSize('content', 0.5) },
                { is: "separator" },
                { icon: "play_arrow",/* text: "Slow",*/ title: "scroll slow", disabled: !this.disabled, click: () => this.triggerScrollToSlowly() },
                { icon: "forward",/* text: "Normal",*/ title: "scroll normal", disabled: !this.disabled, click: () => this.triggerScrollToMedium() },
                { icon: "fast_forward", /*text: "Fast",*/ title: "scroll fast", disabled: !this.disabled, click: () => this.triggerScrollToFast() },
                { is: "spacer" },

                { // Format menu
                    text: this.current_format_name,
                    title: "Format",
                    icon: "crop_free",
                    chevron: true,
                    menu: this.formats.map(([text, w, h]) => {
                        return {
                            text,
                            active: (this.page_format_mm[0] == w && this.page_format_mm[1] == h),
                            click: () => { this.page_format_mm = [w, h]; }
                        }
                    }),
                    menu_width: 80,
                    menu_height: 280
                },
                { // Margins menu
                    text: this.current_margins_name,
                    title: "Margins",
                    icon: "select_all",
                    chevron: true,
                    menu: this.margins.map(([text, value]) => {
                        return {
                            text: text + " (" + value + ")",
                            active: (this.page_margins == value),
                            click: () => { this.page_margins = value; }
                        }
                    }),
                    menu_width: 200,
                    menu_class: "align-center"
                },
                { // Zoom menu
                    text: Math.floor(this.zoom * 100) + "%",
                    title: "Zoom",
                    icon: "zoom_in",
                    chevron: true,
                    menu: [
                        ["200%", 2.0],
                        ["150%", 1.5],
                        ["125%", 1.25],
                        ["100%", 1.0],
                        ["90%", 0.90],
                        ["85%", 0.85],
                        ["80%", 0.80],
                        ["75%", 0.75],
                        ["70%", 0.70],
                        ["60%", 0.60],
                        ["50%", 0.5],
                        ["25%", 0.25]
                    ].map(([text, zoom]) => {
                        return {
                            text,
                            active: this.zoom == zoom,
                            click: () => { this.zoom = zoom; }
                        }
                    }),
                    menu_width: 80,
                    menu_height: 280,
                    menu_class: "align-center"
                },
                { // Display mode menu
                    title: "Display",
                    icon: this.display == "horizontal" ? "view_column" : (this.display == "vertical" ? "view_stream" : "view_module"),
                    chevron: true,
                    menu: [{
                        icon: "view_module",
                        active: this.display == "grid",
                        click: () => { this.display = "grid"; }
                    }, {
                        icon: "view_column",
                        active: this.display == "horizontal",
                        click: () => { this.display = "horizontal"; }
                    }, {
                        icon: "view_stream",
                        active: this.display == "vertical",
                        click: () => { this.display = "vertical"; }
                    }],
                    menu_width: 55,
                    menu_class: "align-right"
                }
            ];
        }
        return _menu;
    }

    get current_format_name() {
        const format = this.formats.find(([, width_mm, height_mm]) => (this.page_format_mm[0] == width_mm && this.page_format_mm[1] == height_mm));
        return format ? format[0] : (this.page_format_mm[0] + "mm x " + this.page_format_mm[1] + "mm");
    }
    set_format_by_name(name: string) {
        const format = this.formats.find(([name_f, width_mm, height_mm]) => (name_f == name));
        if (format != null && format != undefined) {
            this.page_format_mm[0] = format![1];
            this.page_format_mm[1] = format![2];
        }
    }
    get formats() {
        return [
            ["A0", 841, 1189],
            ["A0L", 1189, 841],
            ["A1", 594, 841],
            ["A1L", 841, 594],
            ["A2", 420, 594],
            ["A2L", 594, 420],
            ["A3", 297, 420],
            ["A3L", 420, 297],
            ["A4", 210, 297],
            ["A4S", 105, 297],
            ["A4L", 297, 210],
            ["A5", 148, 210],
            ["A5L", 210, 148],
            ["A6", 105, 148],
            ["A6L", 148, 105]
        ];
    }
    // Margins management
    get current_margins_name() {
        const margins = this.margins.find(([, margins]) => (this.page_margins == margins));
        return margins ? margins[0] : margins![1];
    }
    get margins() {
        return [
            ["Medium", "20mm"],
            ["Small", "15mm"],
            ["Slim", "10mm 15mm"],
            ["Tiny", "5mm"]
        ];
    }
    // Current text style management
    get current_text_style() { return this.editor_mounted ? (this.$refs[this.getEditorCanzoneContainerId] as any).current_text_style : false; }
    get isLeftAligned() { return ["start", "left", "-moz-left"].includes(this.current_text_style.textAlign); }
    get isRightAligned() { return ["end", "right", "-moz-right"].includes(this.current_text_style.textAlign); }
    get isCentered() { return ["center", "-moz-center"].includes(this.current_text_style.textAlign); }
    get isJustified() { return ["justify", "justify-all"].includes(this.current_text_style.textAlign); }
    get isBold() {
        const fontWeight = this.current_text_style.fontWeight;
        return fontWeight && (parseInt(fontWeight) > 400 || fontWeight.indexOf("bold") == 0);
    }
    get isItalic() { return this.current_text_style.fontStyle == "italic"; }
    get isUnderline() { // text-decoration is not overridden by children, so we query the parent stack
        const stack = this.current_text_style.textDecorationStack;
        return stack && stack.some((d: any) => (d.indexOf("underline") == 0));
    }
    get isStrikeThrough() { // text-decoration is not overridden by children, so we query the parent stack
        const stack = this.current_text_style.textDecorationStack;
        return stack && stack.some((d: any) => (d.indexOf("line-through") == 0));
    }
    get isNumberedList() { return this.current_text_style.isList && this.current_text_style.listStyleType == "decimal"; }
    get isBulletedList() { return this.current_text_style.isList && ["disc", "circle"].includes(this.current_text_style.listStyleType); }
    get isH1() { return this.current_text_style.headerLevel == 1; }
    get isH2() { return this.current_text_style.headerLevel == 2; }
    get isH3() { return this.current_text_style.headerLevel == 3; }
    get curColor() { return this.current_text_style.color || "transparent"; }
    // Platform management
    get isMacLike() { return /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform); }
    // Undo / redo flags
    get can_undo() { return !this.disabled && this.undo_count > 0; }
    get can_redo() { return !this.disabled && this.content_history.length - this.undo_count - 1 > 0; }

    overlay(page: any, total: any) {
        //if ((this.isMediumSmallScreen || this.isMediumBigScreen)) {
        //    console.log("total");
        //    console.log(total);
        //    if (total == 1) {
        //        console.log("set A4");
        //        this.set_format_by_name("A4");
        //    }
        //}

        // Add page numbers on each page
        let html = '';
        //let html = '<div style="position: absolute; bottom: 8mm; ' + ((page % 2) ? 'right' : 'left') + ': 10mm">Page ' + page + ' of ' + total + '</div>';
        // Add custom headers and footers from page 3
        //if (page >= 3) {
        //    html += '<div style="position: absolute; left: 0; top: 0; right: 0; padding: 3mm 5mm; background: rgba(200, 220, 240, 0.5)"><strong>MYCOMPANY</strong> example.com /// This is a custom header overlay</div>';
        //    html += '<div style="position: absolute; left: 10mm; right: 10mm; bottom: 5mm; text-align:center; font-size:10pt">MY COMPANY - example.com /// This is a custom footer overlay</div>';
        //}
        return html;
    }
    // Undo / redo functions examples
    undo() { if (this.can_undo) { this._mute_next_content_watcher = true; this.content = this.content_history[--this.undo_count]; } }
    redo() { if (this.can_redo) { this._mute_next_content_watcher = true; this.content = this.content_history[++this.undo_count]; } }
    resetContentHistory() { this.content_history = []; this.undo_count = -1; }
    // Insert page break function example
    async insertPageBreak() {
        // insert paragraph at caret position
        document.execCommand("insertParagraph");
        // insert a marker at caret position (start of the new paragraph)
        const marker = "###PB###"; // must be regex compatible
        document.execCommand("insertText", false, marker);
        // wait for v-model content update (two ticks are needed to reactivate watch on content)
        await this.$nextTick();
        await this.$nextTick();
        // find the marker inside content items and split this content item in two items between the two paragraphs
        // only match root tags (p, div, h1, h2...) to avoid non-root tags like <li>
        const regexp = new RegExp("<(p|div|h\\d)( [^/>]+)*>(<[^/>]+>)*" + marker);
        for (let i = 0; i < this.content.length; i++) {
            const item = this.content[i];
            if (typeof item != "string") continue;
            const match = regexp.exec(item);
            if (match) {
                const tags_open = match[0].slice(0, -marker.length);
                let content_plus_tags_close = item.substr(match.index + match[0].length);
                // insert <br> to empty pages that would not be handled correctly by contenteditable
                if (content_plus_tags_close.indexOf("</") == 0) content_plus_tags_close = "<br>" + content_plus_tags_close;
                this.content.splice(i, 1, item.substr(0, match.index), tags_open + content_plus_tags_close);
                return;
            }
        }
        // if the code didn't return before, the split didn't work (e.g. inside a <li>). just remove the marker from the content
        for (let i = 0; i < this.content.length; i++) {
            const item = this.content[i];
            if (typeof item != "string" || item.indexOf(marker) < 0) continue;
            this.content.splice(i, 1, item.replace(marker, ''));
            break;
        }
    }

    @Watch('content', { immediate: true })
    onCanzoneChanged(new_content: any) {
        if (!this._mute_next_content_watcher) { // only update the stack when content is changed by user input, not undo/redo commands
            this.content_history[++this.undo_count] = new_content;
            this.content_history.length = this.undo_count + 1; // remove all redo items
        }
        this._mute_next_content_watcher = false;
    }

    @Watch('page_format_mm', { immediate: true })
    onPageFormatChanged(new_page_format: any) {
        this.pageformatComputed = new_page_format;
    }

    @Watch('zoom', { immediate: true })
    onZoomChanged(new_zoom: number) {
        this.zoomComputed = new_zoom;
    }

    @Watch('display', { immediate: true })
    onDisplayChanged(new_display: string) {
        this.displayComputed = new_display;
    }

    get getscrolltocontainer() {
        return "#".concat(this.getEditorCanzoneContainerId);
    }

    get getEditorCanzoneContainerId() {
        if (typeof this.canzoneId != "undefined") {
            return "EditorCanzone" + this.canzoneId;
        }
        return "EditorCanzone";
    }

    get getEditorCanzoneScrollToId() {
        if (typeof this.canzoneId != "undefined") {
            return "EditorCanzoneScrollTo" + this.canzoneId;
        }
        else {
            return "EditorCanzoneScrollTo";
        }
    }

    get getscrollto() {
        return "#".concat(this.getEditorCanzoneScrollToId);
    }

    getEditorCanzoneScrollToButtonSlowlyId() {
        if (typeof this.canzoneId != "undefined") {
            return "EditorCanzoneButtonScrollToSlowly" + this.canzoneId;
        }
        else {
            return "EditorCanzoneButtonScrollToSlowly";
        }
    }

    getEditorCanzoneScrollToButtonMediumId() {
        if (typeof this.canzoneId != "undefined") {
            return "EditorCanzoneButtonScrollToMedium" + this.canzoneId;
        }
        else {
            return "EditorCanzoneButtonScrollToMedium";
        }
    }

    getEditorCanzoneScrollToButtonFastId() {
        if (typeof this.canzoneId != "undefined") {
            return "EditorCanzoneButtonScrollToFast" + this.canzoneId;
        }
        else {
            return "EditorCanzoneButtonScrollToFast";
        }
    }

    triggerScrollToSlowly() {
        document.getElementById(this.getEditorCanzoneScrollToButtonSlowlyId())?.click();
    }
    triggerScrollToMedium() {
        document.getElementById(this.getEditorCanzoneScrollToButtonMediumId())?.click();
    }
    triggerScrollToFast() {
        document.getElementById(this.getEditorCanzoneScrollToButtonFastId())?.click();
    }

    get getscrolloption1() {
        if (typeof this.canzoneId != "undefined") {
            return {
                el: this.getscrollto,
                container: this.getscrolltocontainer,
                duration: 250000,
                easing: "ease-in",
                offset: 0,
                lazy: false,
                force: true,
                cancelable: true,
                onStart: this.onStartScrollSlowly,
                onDone: this.onStopScrollSlowly,
                onCancel: this.onStopScrollSlowly,
                x: false,
                y: true
            };
        }
    }

    private scrollSlowlyisPlaying: boolean = false;
    get scrollSlowlyisPlayingComputed() {
        return this.scrollSlowlyisPlaying;
    }
    set scrollSlowlyisPlayingComputed(newValue: boolean) {
        this.scrollSlowlyisPlaying = newValue;
    }
    onStartScrollSlowly() {
        this.scrollSlowlyisPlayingComputed = true;
    }
    onStopScrollSlowly() {
        this.scrollSlowlyisPlayingComputed = false;
    }

    get getscrolloption2() {
        if (typeof this.canzoneId != "undefined") {
            return {
                el: this.getscrollto,
                container: this.getscrolltocontainer,
                duration: 200000,
                easing: "ease-in",
                offset: 0,
                lazy: false,
                force: true,
                cancelable: true,
                onStart: this.onStartScrollMedium,
                onDone: this.onStopScrollMedium,
                onCancel: this.onStopScrollMedium,
                x: false,
                y: true
            };
        }
    }
    
    private scrollMediumisPlaying: boolean = false;
    get scrollMediumisPlayingComputed() {
        return this.scrollMediumisPlaying;
    }
    set scrollMediumisPlayingComputed(newValue: boolean) {
        this.scrollMediumisPlaying = newValue;
    }
    onStartScrollMedium() {
        this.scrollMediumisPlayingComputed = true;
    }
    onStopScrollMedium() {
        this.scrollMediumisPlayingComputed = false;
    }

    get getscrolloption3() {
        if (typeof this.canzoneId != "undefined") {
            return {
                el: this.getscrollto,
                container: this.getscrolltocontainer,
                duration: 150000,
                easing: "ease-in",
                offset: 0,
                lazy: false,
                force: true,
                cancelable: true,
                onStart: this.onStartScrollFast,
                onDone: this.onStopScrollFast,
                onCancel: this.onStopScrollFast,
                x: false,
                y: true
            };
        }
    }

    private scrollFastisPlaying: boolean = false;
    get scrollFastisPlayingComputed() {
        return this.scrollFastisPlaying;
    }
    set scrollFastisPlayingComputed(newValue: boolean) {
        this.scrollFastisPlaying = newValue;
    }
    onStartScrollFast() {
        this.scrollFastisPlayingComputed = true;
    }
    onStopScrollFast() {
        this.scrollFastisPlayingComputed = false;
    }

    private testoCanzone: string = "";

    private editorOptions: any = {};
    get getEditorOptions() {
        if (this.disabled) {
            this.editorOptions = {
                modules: {
                    toolbar: false,
                },
            }
        }
        //console.log(this.editorOptions);
        return this.editorOptions;
    }

    upSemitono() {
        for (let i = 0; i < this.content.length; i++) {
            var item = this.content[i].replace(/<div/g, "<p").replace(/div>/g, "p>");
            item = Transposer.transpose(item).up(1).toString().replace(/\n/g, "").replace(/<p/g, "<div").replace(/p>/g, "div>");
            if (this.readonly) {
                item = item.concat("<p id='" + this.getEditorCanzoneScrollToId + "'/>");
            }
            this.content.splice(i, 1, item);
        }
    }

    downSemitono() {
        for (let i = 0; i < this.content.length; i++) {
            var item = this.content[i].replace(/<div/g, "<p").replace(/div>/g, "p>");
            item = Transposer.transpose(item).down(1).toString().replace(/\n/g, "").replace(/<p/g, "<div").replace(/p>/g, "div>");
            if (this.readonly) {
                item = item.concat("<p id='" + this.getEditorCanzoneScrollToId + "'/>");
            }
            this.content.splice(i, 1, item);
        }
    }

    increaseFontSize(classname: string, increaseFactor: number) {
        var editor = document.getElementById(this.getEditorCanzoneContainerId)?.getElementsByClassName(classname)[0];
        var style = window.getComputedStyle(editor as any, null).getPropertyValue('font-size');
        var currentSize = parseFloat(style);
        (editor as any).style.fontSize = (currentSize + increaseFactor) + 'px';
    }

    decreaseFontSize(classname: string, decreaseFactor: number) {
        var editor = document.getElementById(this.getEditorCanzoneContainerId)?.getElementsByClassName(classname)[0];
        var style = window.getComputedStyle(editor as any, null).getPropertyValue('font-size');
        var currentSize = parseFloat(style);
        (editor as any).style.fontSize = (currentSize - decreaseFactor) + 'px';
    }

    print() {
        this.$htmlToPaper(this.getEditorCanzoneContainerId)
    }

    cleancCanzoneFormGroup() {
        this.content = [];

    }

    mounted() {           
        if (typeof this.testo != "undefined") {
            if (this.readonly) {
                if (this.testo != null)
                    this.testoCanzone = this.testo.concat("<p id='" + this.getEditorCanzoneScrollToId + "'/>");
            }
            else {
                this.testoCanzone = this.testo;
            }

        }
        if (this.testoCanzone != null && typeof this.testoCanzone != "undefined") {
            this.content[0] = this.testoCanzone.replace(/<p/g, "<div").replace(/p>/g, "div>");
        }
        console.log(this.canzoneId);
        if (typeof this.canzoneId != "undefined") {
            this.nomeStorePageformat = 'editorCanzonePageformat' + this.canzoneId;
            console.log("pageformatComputed");
            console.log(this.pageformatComputed);
            this.page_format_mm = this.pageformatComputed;
            this.nomeStoreZoom = 'editorCanzoneZoom' + this.canzoneId;
            this.zoom = this.zoomComputed;
            this.nomeStoreDisplay = 'editorCanzoneDisplay' + this.canzoneId;
            this.display = this.displayComputed;
        }
        this.editor_mounted = true;
    }
}


